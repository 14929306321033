export const locale = {
    header_title: 'Sajlha',
    TRANSLATOR: {
        SELECT: "Select your language"
    },
    MENU: {
        NEW: "new",
        ACTIONS: "Actions",
        CREATE_POST: "Create New Post",
        PAGES: "Pages",
        FEATURES: "Features",
        APPS: "Apps",
        DASHBOARD: "Dashboard",
        USERS: "Users",
        Roles_Management: 'Roles Management',
        users_roles_management: 'ROLES & USERS',
        company_profile: 'Company Profile',
        change_email_password: 'Change Email & Password',
        user_Management: 'User Management',
        Countries: 'Countries',
        country_management: 'Country Management',
        Cities: 'Cities',
        cities_management: 'City Management',
        setting_management: 'Setting Management',
        Categories: 'Categories',
        module_settings: 'Modules Settings',
        module_settings_sub_actions: 'Module settings Sub Actions',
        business_field: 'Business Field',
        business_field_management: 'Business Field Management',
        register_company_management: 'Register Company Management',
        register_company: 'Register Company',
        subscription: 'Subscriptions',
        plans: 'Plans',
        request_tenant_subdomain: 'Requests Subdomains',
        management_subdomain: 'Management Subdomains',
        management_database: 'Management Database',
        required_tenants: 'Tenants Requests',
        reports_subscriptions: 'Reports Subscriptions',
        reports: 'Reports',
        payments_moyasar: 'Payments Moyasar',
        payments_paypal: 'Payments PayPal',
        payments_cash: 'Payments Cash',
        agents_management: 'Agents Management',
        agents: 'Agents',
        commission_management: 'Commissions Management',
        agents_and_customers: 'Agents and Customers',
        apply_commission: 'Apply Commission',
        reports_agents_dues: 'Report Agents Dues',
        partners: 'Partners',
        all_notifications: 'All Notifications',
        offers: 'Offers Management',
        extension_requests: 'Subscription Extension Requests',
        discounts: 'Discounts',
        periods: 'Periods',
        sync_endpoint: 'Settings Endpoint',
        reports_expired_subscriptions: 'Report Expired Subscriptions',
        bank_transfers : 'Bank Transfers',
        admin_accounts : 'Admin Accounts',
    },
    AUTH: {
        GENERAL: {
            OR: "Or",
            SUBMIT_BUTTON: "Submit",
            NO_ACCOUNT: "Don't have an account?",
            SIGNUP_BUTTON: "Sign Up",
            FORGOT_BUTTON: "Forgot Password",
            BACK_BUTTON: "Back",
            PRIVACY: "Privacy",
            LEGAL: "Legal",
            CONTACT: "Contact"
        },
        LOGIN: {
            TITLE: "Login Account",
            BUTTON: "Sign In"
        },
        FORGOT: {
            TITLE: "Forgot Password?",
            DESC: "Enter your email to reset your password",
            SUCCESS: "Your account has been successfully reset."
        },
        REGISTER: {
            TITLE: "Sign Up",
            DESC: "Enter your details to create your account",
            SUCCESS: "Your account has been successfuly registered."
        },
        INPUT: {
            EMAIL: "Email",
            FULLNAME: "Fullname",
            PASSWORD: "Password",
            CONFIRM_PASSWORD: "Confirm Password",
            USERNAME: "Username"
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
    },
    Do_you_want_delete_it: 'Do you want delete it?',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    ok: 'Ok',
    close: 'Close',
    save: 'Save',
    cancel: 'Cancel',
    actions: 'Actions',
    Something_went_wrong: 'Oops! Something went wrong',
    Filter: 'Filter',
    Search_query: 'Quick search',
    Search: 'Search',
    Loading: 'Loading',
    edit: 'Edit',
    delete: 'Delete',
    back: 'Back',
    records: 'Records',
    yes: 'Yes',
    no: 'No',
    all: 'All',
    advanced_filter: 'Advanced filter',
    reset_search: 'Reset search',
    main: 'Main',
    others: 'Others',
    active: 'Active',
    inactive: 'Inactive',
    client: 'Client',
    insurance_agent: 'Insurance Agent',
    status: 'Status',
    is_default: 'Is default',
    internal: 'Internal',
    external: 'External',
    created_at: 'Created at',
    upload_logo: 'Upload logo',
    add_more: 'Add more',
    from_date: 'From date',
    to_date: 'To date',
    export: 'Export',
    excel: 'Excel',
    generate_pdf: 'Generate PDF',
    custom_export: 'Custom export',
    select_all: 'Select all',
    add_new: 'Add New',
    change_status: 'Change status',
    Hi: 'Hi',
    User_Profile: 'User Profile',
    Sign_out: 'Sign out',
    not_selected: 'Not selected',
    more_actions: 'More actions',
    commands: 'Commands',
    extra_options: 'Extra options',
    all_transactions: 'All Transactions',
    no_data_to_show: 'No data to show',
    clone: 'Clone',
    link: 'Link',
    finished: 'Finished',
    unfinished: 'Unfinished',
    Added_By: 'Added By',
    unpaid: 'Unpaid',
    paid: 'Paid',
    products: 'Products',
    clients: 'Clients',
    invoice: 'Invoice',
    search: 'Search',
    print_last_invoice: 'Print last invoice',
    session_validate: 'Session validate',
    product_information: 'Product information',
    payment: 'Payment',
    add_client: 'Add client',
    payment_method: 'Payment method',
    payment_amount: 'Payment amount',
    payment_list: 'Payment list',
    discount: 'Discount',
    order_details: 'Order Details',
    cash_payment: 'Cash Payment',
    from: 'From',
    amount: 'Amount',
    paid_by: 'Paid By',
    received_by: 'Received By',
    number_no: 'No.',
    signature: 'Signature',
    do_you_want_make_it_as_draft: 'Do you want make it as draft?',
    do_you_want_to_close_session: 'Do you want to close the session?',
    no_transactions_found: 'No transactions found',
    no_invoices_found: 'No invoices found',
    no_refund_receipts_found: 'No refund receipts found',
    no_payments_found: 'No payments found',
    pos_client: 'POS Client',
    screen: 'Screen',
    print: 'Print',
    pdf: 'PDF',
    setup_installment_agreement: 'Setup installment agreement',
    create_credit_note: 'Credit note',
    assign_costs_centers: 'Assign costs centers',
    qty: 'Qty',
    accounts: 'Accounts',
    paginationLimit: 'Records',
    paginationCount: 'Count',
    paginationFirst: 'First',
    paginationLast: 'Last',
    paginationFilter: 'Filter',
    paginationPage: 'Page',
    paginationNoResult: 'No Result',
    paginationFilterBy: 'Filter By',
    paginationLoading: 'Loading',
    paginationDefaultOption: 'Default Option',
    No_sub_options: 'No sub-options.',
    No_options_available: 'No options available.',
    No_results_found: 'No results found...',
    Select: 'Select...',
    added_by: 'Added By',
    mark_as_draft: 'Mark as draft',
    add_payment: 'Add payment',
    customer_search: 'Customer search',
    customer_name: 'Customer name',
    start_session: 'Start session',
    create_invoice: 'Create invoice',
    pos_session_n: 'POS session',
    invoices: 'Invoices',
    clients_n: 'Clients',
    new_client: 'New client',
    sales: 'Sales',
    payments_summary: 'Payments Summary',
    profit_and_loss: 'Profit & Loss',
    income_and_expenses: 'Income & Expenses',
    recent_invoices: 'Recent Invoices',
    overdue_invoices: 'Overdue Invoices',
    payment_statistics: 'Payment Statistics',
    invoice_search: 'Invoice Search',
    invoice_number: 'Invoice Number',
    recent_payments: 'Recent Payments',
    low_stock_products: 'Low Stock Products',
    last_appointments: 'Last Appointments',
    view_all: 'View All',
    activity_logs: 'Activity Logs',
    num_movements: 'Number of movements',
    available: 'Available',
    out_of_stock: 'Out Of Stock',
    expense: 'Expenses',
    income: 'Income',
    refund: 'Refund',
    low_of_stock: 'Low Of Stock',
    to: 'To',
    details: 'Details',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    customer: 'Customer',
    employee: 'Employee',
    select_filter: 'Select filter',
    revenue_invoice: 'Revenue invoice',
    collected_by: 'Collected by',
    supplier: 'Supplier',
    brand: 'Brand',
    product: 'Product',
    show_draft: 'Show draft',
    staff: 'Staff',
    daily_products: 'Daily Products',
    summery: 'Summery',
    time: 'Time',
    group_by: 'Group by',
    due_date: 'Due date',
    order_by: 'Order by',
    asc: 'Ascending',
    desc: 'Descending',
    partial_paid: 'Partial paid',
    overdue: 'Overdue',
    summary: 'Summary',
    prefix: 'Prefix',
    digits: 'Digits',
    enter: 'Enter',
    please_upload_valid_file: 'Please upload valid file',
    load_more: 'Load more',
    create: 'Create',
    update: 'Update',
    arabic: 'Arabic',
    english: 'English',
    copied: 'Copied',
    copy: 'Copy',
    referral_link: 'Referral link',
    generate_key: 'Generate key',
    make_all_read: 'Make all read',
    unread_notifications: 'Unread notification',
    global: {
        actions: 'Actions',
        yes: 'Yes',
        no: 'No',
        all: 'All',
    },
    header: {
        my_profile: 'My profile',
        account_settings_and_more: 'Account settings and more',
        change_email_password: 'Change Email & Password',
    },
    roles: {
        roles_management_section: 'Roles management section',
        role_name: 'Role name',
        description: 'Description',
        status: 'Status',
        created_at: 'Created at',
        new_role: 'New role',
        role: 'Role',
        edit_role: 'Edit role',
    },
    users: {
        users_management: 'Users management',
        users: 'Users',
        user_name: 'User name',
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        password: 'Password',
        status: 'Status',
        created_at: 'Created at',
        new_user: 'New user',
        user: 'User',
        edit_user: 'Edit user',
        roles: 'Roles',
        description: 'Description',
        first_name: 'First name',
        last_name: 'Last name',
        countries: 'Countries',
        cities: 'Cities',
        confirm_password: 'Confirm password',
        active: 'Active',
        inactive: 'Inactive',
        add_user: 'Add user',
        pl_first_name: 'Enter first name',
        pl_last_name: 'Enter last name',
        pl_email: 'Enter email',
        pl_password: 'Enter password',
        pl_confirm_password: 'Enter confirm password',
        pl_phone: 'Enter phone',
        pl_role: 'Enter roles',
    },
    user_settings: {
        change_email_password: 'Change email & password',
        change_email: 'Change email',
        current_password: 'Current password',
        email: 'Email',
        new_password: 'New password',
        confirm_password: 'Confirm password',
        change_password: 'Change password',
    },
    company: {
        company_profile: 'Company profile',
        basic_information: 'Basic information',
        business_name: 'Business name',
        business_type: 'Business type',
        currency: 'Currency',
        language: 'Language',
        time_zone: 'Time zone',
        date_format: 'Date format',
        printing_method: 'Printing method',
        upload_logo: 'Upload logo',
        address_information: 'Address information',
        country: 'Country',
        city: 'City',
        postal_code: 'Postal code',
        district: 'District',
        address_1: 'Address 1',
        address_2: 'Address 2',
        mobile: 'Mobile',
        phone: 'Phone',
        email: 'Email',
        first_name: 'First name',
        last_name: 'Last name',
        contact_information: 'Contact information',
    },
    login_page: {
        Discover_Amazing_Sajlha_with_great_build_tools: "Discover Amazing Sajlha <br />with great build tools",
        Welcome_to_Sajlha: 'Welcome to Sajlha',
        Email: 'Email',
        Password: 'Password',
        Sign_In: 'Sign In',
        Sign_Up: 'Sign Up',
        Enter_your_details_to_create_your_account: 'Enter your details to create your account',
        Submit: 'Submit',
        Cancel: 'Cancel',
        Forgotten_Password: 'Forgotten Password ?',
        Enter_your_email_to_reset_your_password: 'Enter your email to reset your password',
        Terms: 'Terms',
        Plans: 'Plans',
        Contact_Us: 'Contact Us',
        Username_is_required: 'Username is required',
        Password_is_required: 'Password is required',
        Full_name_is_required: 'Full name is required',
        Email_is_required: 'Email is required',
        The_value_is_not_a_valid_email_address: 'The value is not a valid email address',
        Confirm_password_is_required: 'Confirm password is required',
        The_password_and_its_confirm_are_not_the_same: 'The password and its confirm are not the same',
        You_should_agree_terms_and_conditions: 'You should agree terms and conditions',
        Please_provide_correct_data: 'Please, provide correct data!',
    },
    errors: {
        the_page_not_found: 'The page not found',
        you_dont_have_permission: 'You don\'t have permission',
    },
    countries: {
        country_management: 'Country Management',
        countries: 'Countries',
        country: 'Country',
        phone_code: 'Phone code',
        en_name: 'English name',
        ar_name: 'Arabic name',
        code2: 'Code 2',
        code3: 'Code 3',
    },
    cities: {
        new_city: 'New city',
        cities: 'Cities',
        city_management: 'City Management',
        city: 'City',
        en_name: 'English name',
        ar_name: 'Arabic name',
        code2: 'Code 2',
        code3: 'Code 3',
        status: 'Status',
    },
    categories: {
        categories: 'Categories',
        add_new_category: 'Add new category',
        add_new_sub_category: 'Add new sub category',
        name: 'Name',
        description: 'Description',
        upload_image: 'Upload image',
    },
    module_settings: {
        new_module_setting: 'New module setting',
        module_name: 'Module name',
        date: 'Date',
        user: 'User',
        general_name: 'General name',
        module_settings: 'Module settings',
        summery: 'Summery',
        details: 'Details',
        upload_image: 'Upload image',
        title: 'Title',
        multi_images: 'Multi images',
        image: 'Image',
        priority: 'Priority',
        sub_actions: 'Sub actions',
    },
    business_fields: {
        business_field: 'Business field',
        new_business_field: 'Add business field',
        plan: 'Plan',
    },


    register_company: {
        add_new_account: 'Add new account',
        name: 'Name',
        just_two_minutes_to_get_started: 'Just two minutes to get started',
        trade_name: 'Trade name',
        email: 'Email',
        login_page: 'Login page',
        password: 'Password',
        confirm_password: 'Confirm password',
        what_you_need_to_run_your_business_in_one_program: 'What you need to run your business in one program',
        get_started_for_free: 'Get started for free',
        all_of_these_features_are_customized_to_your_industry: 'All of these features are customized to your industry',
    },

    sync_endpoint: {
        name: 'Name',
        end_point: 'End point',
        method: 'Method',
        type: 'Type',
        description: 'Description',
        from: 'From',
        to: 'To',
    },

    plans: {
        name: 'Name',
        modules_used: 'Modules used',
        date: 'Date',
        price_per_month: 'Price per month',
        price_per_year: 'Price per year',
        currency: 'Currency',
        plan_type: 'Plan type',
        number_company_branches: 'Number of company branches',
        number_company_inventory: 'Number of company inventory',
        number_pos: 'Number of point of sale',
        number_employee: 'Number of employee',
        number_customers: 'Number of customers',
        number_items: 'Number of items',
        new_plan: 'New plan',
        from_date: 'From date',
        to_date: 'To date',
        user: 'User',
        plans: 'Plans',
        priority: 'Priority',
        details: 'Details',
        free_plan: 'The plan is basic',
        available_days: 'Available days',
        permissions: 'Permissions',
        number_users: 'Number of users',
        number_sales_invoices: 'Number of sales invoices',
        price_per_3_month: 'Plan price for 3 months',
        price_per_6_month: 'Plan price for 6 months',
        notes: 'Notes',
        unlimited: 'Unlimited',
        offer_name: 'Offer name',
        offer_date: 'Offer date',
        offer_free_days: 'Offer free days',
        offers_applied: 'Offers applied',
        offers_log: 'Offers log',
        plan_services: 'Plan services',
        additional_pos_price: 'Additional point of sale price',
        additional_branch_price: 'Additional branch price',
        extension_days: 'Number of extension days',
        additional_user_price: 'Additional user price',
        maximum_amount_sales: 'The maximum amount of sales',
        is_free_trial: 'Is a free trial allowed?',
        free_trial_days: 'The number of free trial days',
        is_custom_program: 'Custom program',
        basic_features: 'Basic features',
        title: 'Title',
        summary: 'Summary',
        print_setting_details: 'Print settingdetails',
        invoices_bond_configuration : 'Invoices bond configuration',
        doc_type: 'Document type',
        code_settings : 'Code settings',
        permission_management : 'Permission management',
        print_setting_management : 'Print setting management',
        invoices_bonds_management : 'Invoices bond management',
        code_settings : 'Code settings',
        service_type : 'Service type',
        price : 'Price',
        subscription_extension_for_expired_companies : 'Subscription extension for expired companies',
    },
    subdomain: {
        account_no: 'Account no',
        db_flag: 'DB flag',
        subdomain_flag: 'Subdomain flag',
        db_type: 'DB type',
        from_date: 'From date',
        to_date: 'To date',
        details: 'Details',
        subdomain: 'Subdomain',
        uuid: 'UUID',
        attachment: 'Attachment',
        link: 'Link',
        add_tenant: 'Add tenant',
        amount : 'Amount',
        subscription_type: 'Subscription type',
        monthly: "Monthly",
        yearly: "Yearly",
        upgrade_plan: 'Upgrade plan',
        add_payment: 'Add payment',
        change_expire_date: 'Change expire date',
        expire_date: 'Expire date',
        plan : 'Plan',
        permission_management : 'Permission management',
        print_setting_management : 'Print setting management',
        invoices_bonds_management : 'Invoices bond management',
        code_settings : 'Code settings',
    },
    activity_log: {
        basic_information: 'Basic information',
        activity_log : 'Activity log',
    },
    management_subdomain: {
        assign_account_no: 'Assign account no',
        business_name_subdomain: 'Business name',
        available_flag: 'activation status',
        notes: 'Notes',
        from_date: 'From date',
        to_date: 'To date',

        db_name: 'DB name',
        business_name: 'Business name',
        expire_date: 'Expire date',
        plan_name: 'plan',
        subscription_information: 'Subscription information',
        first_name: 'First name',
        domain : 'Domain',
        last_name: 'Last name',
        email: 'Email',
        country: 'Country',
        city: 'City',
        address1: 'Address 1',
        address2: 'Address 2',
        start_date: 'Start date for subscription',
        end_date: 'End date for subscription',
        phone: 'Phone',
        company_name: 'Company name',
        active_from_date: 'Active from date',
        active_to_date: 'Active to date',
        expired_account: 'Expired accounts',
    },
    management_database: {
        assign_account_no: 'Assign account no',
        name_db: 'Database name',
        user_name_db: 'Database user name',
        password_db: 'Database password',
        available_flag: 'Available flag',
        notes: 'Notes',
        from_date: 'From date',
        to_date: 'To date',
        databases_without_companies: 'Databases without companies',
    },
    required_tenant: {
        assign_account_no: 'Assign account no',
        available_flag: 'Available flag',
        notes: 'Notes',
        from_date: 'From date',
        to_date: 'To date',
    },
    reports_subscriptions: {
        assign_account_no: 'Assign account no',
        business_name_subdomain: 'Business name subdomain',
        from_date: 'From date',
        to_date: 'To date',
        plan_name: 'Plan',
        first_name: 'First name',
        last_name: 'Last name',
        phone: 'Phone',
        email: 'Email',
        payment_type: 'Payment type',
        postal_code: 'Postal code',
        subscription_price: 'Subscription price',
        start_date: 'Start date',
        end_date: 'End date',
        reports_subscriptions: 'Reports subscriptions',
        company: 'Company',
        subscription_type: 'Subscription type',
    },
    statistics: {
        total_number_of_clients: 'Total number of clients',
        the_number_of_customers_on_the_free_plan: 'The number of customers on the free plan',
        The_number_of_clients_on_the_first_plan: 'The number of clients on the first plan',
        the_number_of_customers_on_the_second_plan: 'The number of customers on the second plan',
        total_amounts_for_the_last_year: 'Total amounts for the last year',
        total_amounts: 'Total amounts',
        the_number_of_customers_suspended_due_to_payment: 'The number of customers suspended due to payment',
        number_of_plans: 'Number of plans',
        more_domain_number_of_users: 'Plan for the highest number of users',
        minimum_number_of_users: 'Plan for the lowest number of users',
        the_expected_monthly_amount_of_payment: 'The expected monthly amount of payment',
        the_number_of_accounts_that_pay_an_annual_subscription: 'The number of accounts that pay an annual subscription',
        the_number_of_accounts_the_first_plan_a_monthly_subscription: 'The number of accounts, the first plan, a monthly subscription',
        the_number_of_accounts_is_a_second_plan_a_monthly_subscription: 'The number of accounts is a second plan, a monthly subscription',
        number_of_accounts_third_plan_monthly_subscription: 'Number of accounts, third plan, monthly subscription',
        the_number_of_accounts_is_the_first_annual_subscription_plan: 'The number of accounts is the first annual subscription plan',
        the_number_of_accounts_is_a_second_plan_an_annual_subscription: 'The number of accounts is a second plan, an annual subscription',
        number_of_accounts_third_plan_annual_subscription: 'Number of accounts, third plan, annual subscription',
    },
    payments: {
        from_date: 'From date',
        to_date: 'To date',
        payer_id: 'Payer ID',
        payment_method: 'Payment method',
        amount: 'Amount',
        currency: 'Currency',
        payment_id: 'Payment ID',
    },
    agents: {
        name: 'Name',
        full_name: 'Full name',
        email: 'Email',
        mobile: 'Mobile',
        from_date: 'From date',
        to_date: 'To date',
        user_agent: 'User agent',
        country: 'Country',
        no_of_customer: 'No of customer',
        created_at: 'Created at',
        total_sales_amount: 'Total sales amount',
        add_agent: 'Add agent',
        first_name: 'First name',
        middle_name: 'Middle name',
        last_name: 'Last name',
        phone: 'Phone',
        nationality: 'Nationality',
        default_language: 'Default language',
        supported_language: 'Supported language',
        supported_country: 'Supported country',
        supported_filed: 'Supported filed',
        currency: 'Currency',
        is_general_country: 'Is general country',
        is_general_field: 'Is general field',
        discount: 'Discount percentage',
    },
    commission: {
        agent_name: 'Agent name',
        from_date: 'From date',
        to_date: 'To date',
        from_commission: 'From commission',
        to_commission: 'To commission',
        from_discount: 'From discount',
        to_discount: 'To discount',
        applied_date: 'Applied date',
        discount_rate: 'Discount rate',
        commission_rate: 'Commission rate',
        general_interval: 'General interval',
        add_commission: 'Add commission',
        month: 'Month',
        year: 'Year',
        commission: 'Commission',
    },
    agents_customers: {
        agent_name: 'Agent name',
        from_date: 'From date',
        to_date: 'To date',
        client_name: 'Client name',
        start_date: 'Start date',
        end_date: 'End date',
        no_of_payment: 'No of payment',
        from_rate: 'From rate',
        to_rate: 'To rate',
        total_payment: 'Total payment',
    },
    apply_commission: {
        add_apply_commission: 'Add apply commission',
        agent_name: 'Agent',
        customer_name: 'Customer',
        from_date: 'From date',
        to_date: 'To date',
        amount: 'Amount',
        pay: 'Pay',
        pay_for_agent: 'Pay for agent',
        payment_method: 'Payment method',
        payment_notes: 'Payment notes',
        paid: 'Paid',

    },
    payment_methods: {
        credit_card: 'Credit card',
        paypal: 'Paypal',
        cash: 'Cash',
        bank_transfer: 'Bank transfer',
    },
    reports_agents_dues: {
        agent_name: 'Agent name',
        from_date: 'From date',
        to_date: 'To date',
        received_amount: 'Received amount',
        remaining_amount: 'Remaining amount',
        total_amount: 'Total amount',
    },
    partners: {
        full_name: 'Full name',
        email: 'Email',
        mobile: 'Mobile',
        from_date: 'From date',
        to_date: 'To date',
        country: 'Country',
        created_at: 'Created at',
        fields: 'Fields',
        phone: 'Phone',
        individual: 'Individual',
        company: 'Company',
        company_name: 'Company name',
        business_number: 'Business number',
        city: 'City',
        company_type: 'Company type',
        details: 'Details',
    },

    notifications: {
        user_notifications: 'User notifications',
        no_new_notifications: 'No new notifications',
    },

    offers: {
        add_offer: 'Add offer',
        name: 'Name',
        users_number: 'Users number',
        percentage: 'Percentage',
        start_date: 'Start date',
        end_date: 'End date',
        notes: 'Notes',
        is_active: 'Status',
        plans: 'Plans',
        from_date: 'From date',
        to_date: 'To date',
        plan: 'Plan',
        interval: 'Interval',
        free_days: 'Free days',
    },

    extension_requests: {
        user_name: 'User name',
        business_name_subdomain: 'Business name subdomain',
        business_name: 'Business name',
        plan_name: 'Plan name',
        subscription_date: 'Subscription date',
        registration_date: 'Registration date',
        expire_date: 'Expire date',
        last_amount_paid: 'Last amount paid',
        request_date: 'Request date',
        days_number: 'Days number',
        from_date: 'From date',
        to_date: 'To date',
        reasons: 'Reasons',
    },

    discounts: {
        add_discount: 'Add discount',
        name: 'Name',
        number_users: 'Users number',
        percentage: 'Percentage',
        start_date: 'Start date',
        end_date: 'End date',
        notes: 'Notes',
        is_active: 'Status',
        plans: 'Plans',
        from_date: 'From date',
        to_date: 'To date',
        plan: 'Plan',
        interval: 'Interval',
        applied_interval: 'Applied interval',
    },
    periods: {
        periods: 'Periods',
        number: 'Number',
        period: 'Period',
        is_active: 'Status',
    },

    admin_account: {
        company_name: 'Company name',
        email: 'Email',
        password: 'Password',
    }
}